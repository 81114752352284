import { logout } from 'dsaid-common';
import { atom, DefaultValue, selector } from 'recoil';

import { UserPermissionsEnum } from '@app/enums';

export const userInfoState = atom<UserInfo | null>({
  key: 'userInfoState',
  default: null,
});

export const userInfoSelector = selector<UserInfo | null>({
  key: 'userInfoSelector',
  get: ({ get }) => get(userInfoState),
  set: ({ set, reset }, payload) => {
    if (payload instanceof DefaultValue) {
      reset(userInfoState);
      return;
    }

    const edmAdmins = process.env.REACT_APP_EDM_ADMIN_EMAILS?.split(',') ?? [];

    if (payload && edmAdmins.includes(payload.email)) {
      payload.permissions = payload.permissions ?? [];
      payload.permissions.push(UserPermissionsEnum.EdmAdmin);
    }

    set(userInfoState, payload);
  },
});

export const logoutSelector = selector({
  key: 'logoutSelector',
  get: () => undefined,
  set: ({ reset }, payload) => {
    if (payload instanceof DefaultValue) {
      logout();
      reset(userInfoState);
    }
  },
});
