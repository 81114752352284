export enum ModuleTypeEnum {
  Text = 'text',
  Image = 'image',
  ImageText = 'image-text',
  Header = 'header',
  Footer = 'footer',
  Spacer = 'spacer',
  Row = 'row',
  Block = 'block',
  Button = 'button',
}

export enum DroppableNames {
  DesignArea = 'design-area',
  AvailableModules = 'available-modules',
  ModuleConfigArea = 'module-config-area',
  DroppableArea = 'droppable-area',
  //
  RowDroppableArea = 'row-droppable-area',
  ColumnDroppableArea = 'column-droppable-area',
  EmptyEDM = 'droppable-empty-edm',
  Block = 'block-modules',
}

export enum DroppableTypes {
  Row = 'droppable-row',
  Column = 'droppable-column',
}

export enum UserPermissionsEnum {
  EdmAdmin = 'edm-admin',
}

export enum GAEventsEmum {
  DuplicateCampaign = 'Duplicate Campaign',
  CloneTemplate = 'Clone Template',
  RemoveCampaign = 'Remove Campaign',
  ToggleABTesting = 'Toggle AB Testing',
}

export enum EdmTypeEnum {
  Template = 'template',
  Layout = 'layout',
  Private = 'private',
}

declare global {
  enum ModuleTypeEnum {
    Text = 'text',
    Image = 'image',
    ImageText = 'image-text',
    Header = 'header',
    Footer = 'footer',
    Spacer = 'spacer',
    Row = 'row',
    Block = 'block',
    Button = 'button',
  }

  enum DroppableNames {
    DesignArea = 'design-area',
    AvailableModules = 'available-modules',
    ModuleConfigArea = 'module-config-area',
    DroppableArea = 'droppable-area',
    //
    RowDroppableArea = 'row-droppable-area',
    ColumnDroppableArea = 'column-droppable-area',
  }
}
