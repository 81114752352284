import { atom } from 'recoil';

export const isGlobalLoadingState = atom<boolean>({
  key: 'isGlobalLoadingState',
  default: false,
});

export const loadingQueueState = atom<boolean[]>({
  key: 'loadingQueueState',
  default: [],
});
