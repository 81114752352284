import { isEqualWith, mergeWith } from 'lodash-es';

export const convertHexToRGBA = (hexCode: string = '', opacity: number) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getPercentage(n: number = 0) {
  return Math.round(n * 100);
}

export function tryParseJson<T extends object>(str: string, defaultValue?: T) {
  try {
    return JSON.parse(str) as T;
  } catch (e) {
    console.debug('Parse JSON Error');

    return defaultValue as T;
  }
}

const patterns = {
  email:
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
};

export type PatternType = keyof typeof patterns;
// to remove after refactoring LoginPage
export const validateBy = (type: PatternType, value: string) => {
  const pattern = patterns[type];

  if (!pattern) return true;

  return pattern.test(value);
};

export function hasChanged(obj1: any, obj2: any, keys: string[] = []) {
  if (!obj1 || !obj2) return false;

  const changed = !isEqualWith(
    obj1,
    obj2,
    function (_, __, key: string | number | symbol | undefined) {
      if (typeof key === 'string' && keys.length > 0) {
        if (keys.includes(key)) return undefined;
        return true;
      }

      if (key === 'template' || key === 'isNew') return true;

      return undefined;
    }
  );

  // if (changed) {
  //   console.group('comparasion has changed');
  //   console.debug(JSON.stringify(obj1));
  //   console.debug('------------');
  //   console.debug(JSON.stringify(obj2));
  //   console.groupEnd();
  // }

  return changed;
}

export function tracking(data: any) {
  (window as any).dataLayer?.push({ ...data });
}

function customizer(objValue: any, srcValue: any): any {
  if (Array.isArray(srcValue)) {
    return srcValue;
  }

  if (srcValue && typeof srcValue === 'object') {
    return mergeWith(objValue, srcValue, customizer);
  }

  return typeof srcValue !== 'undefined' ? srcValue : objValue;
}

export function mergeDeep(...args: object[]) {
  // @ts-ignore
  return mergeWith.apply(null, [...args, customizer]);
}
