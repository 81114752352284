import loadable from '@loadable/component';

export const routes: RouteConfig[] = [
  {
    path: '/campaign/new',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/NewCampaign')),
  },
  {
    path: '/campaigns',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/CampaignManagement')),
  },
  {
    path: '/campaign/edit/:id',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/NewCampaign')),
  },
  {
    path: '/campaign/:id/:taskId?',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/CampaignDetails')),
  },
  {
    path: '/subscription/opt-out/:id',
    exact: true,
    component: loadable(() => import('../pages/Subscribe/OptOut')),
  },
];
