import loadable from '@loadable/component';

export const routes: RouteConfig[] = [
  {
    path: '/edm/:id?',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/EDMComposer')),
  },
  {
    path: '/blocks',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/BlockMgmt')),
  },
];
