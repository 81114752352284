import { getUserInfo, logout } from 'dsaid-common';
import { memo, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router';
import { RouteProps } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { OverlayLoading } from './OverlayLoading';
import { userInfoSelector } from './recoil/user';

export function getAuthUrl() {
  return `${process.env.REACT_APP_AUTH_API ?? ''}/auth`;
}

export const AuthRoute = memo<RouteProps>((props) => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useRecoilState(userInfoSelector);
  const [isLoading, toggleLoading] = useState(false);

  useEffect(() => {
    const abort = new AbortController();
    async function getInfo() {
      toggleLoading(true);

      const resp = await getUserInfo(getAuthUrl() ?? '', abort.signal);

      if (!resp) {
        logout();
        history.push('/login');
      }

      // store user info
      setUserInfo(resp?.sub);

      toggleLoading(false);
    }

    if (!userInfo) {
      getInfo();
    }

    return () => {
      abort.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !userInfo) {
    return <OverlayLoading />;
  }

  return <Route {...props} />;
});
