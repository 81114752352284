import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    primary: '#7F63F4',
    bg: '#f9f9f9',
    border: '#f0f0f0',
    borderLight: 'rgba(0,0,0,0.06)',
    link: '#40a9ff',
    disabled: '#D5D5D5',
    tableHeader: '#2c6ca0',
    tableRowEven: '#f9f9f9',
    danger: '#ff4d4f',
    error: '#ff4d4f',
    warning: '#f8d922',
    subtle: '#464A53',
    success: '#52c41a',
    validating: '#dedede',
    text: '#333333',
    tags: {
      danger: '#fdc8c8',
      success: '#d0ffd1',
      primary: '#c3e2fb',
    },
    placeholder: '#D8D8D8',
    shadow:
      '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)',
  },
  fontSize: {
    base: '14px',
    body: '1rem',
    xs: '0.778rem',
    sm: '0.889rem',
    md: '1.222rem',
    lg: '2.006rem',
  },
  spacing: {
    xs: '0.5rem',
    sm: '0.8rem',
    md: '1.5rem',
    lg: '2rem',
  },
};

const BASE_BREAKPOINTS = {
  'min-xs': 480,
  'min-sm': 576,
  'min-md': 768,
  'min-lg': 992,
  'min-xl': 1200,
  'min-xxl': 1600,
};

const breakpoints = {
  ...BASE_BREAKPOINTS,
  'max-xs': BASE_BREAKPOINTS['min-xs'] - 1,
  'max-sm': BASE_BREAKPOINTS['min-sm'] - 1,
  'max-md': BASE_BREAKPOINTS['min-md'] - 1,
  'max-lg': BASE_BREAKPOINTS['min-lg'] - 1,
  'max-xl': BASE_BREAKPOINTS['min-xl'] - 1,
  'max-xxl': BASE_BREAKPOINTS['min-xxl'] - 1,
};

export function getMedia(key: keyof typeof breakpoints) {
  const type = key.startsWith('min') ? 'min' : 'max';
  return `@media screen and (${type}-width: ${breakpoints[key]}px)`;
}
