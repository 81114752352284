import { createGlobalStyle } from 'styled-components';

import { getMedia } from './theme';
import { convertHexToRGBA } from './utils';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${(p) => p.theme.fontSize.base};
  }

  body.overflow-hidden {
    overflow: hidden;
  }
   
  b,strong {
    font-weight: 500
  }

  .ant-upload-text {
    padding-left: ${(p) => p.theme.spacing.xs};
    padding-right: ${(p) => p.theme.spacing.xs};
  }

  .ant-divider-horizontal.no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }

  .tabs-left-in-modal.ant-tabs-left {
    > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
      padding: 16px;
      
      overflow-y: auto;
      height: 100%;
    }

    > .ant-tabs-nav .ant-tabs-tab {
      padding-left: 16px;
      transition: all 0.3s ease;

      &.ant-tabs-tab-active,
      &:hover{ 
        background-color: ${(p) => convertHexToRGBA(p.theme.colors.primary, 10)};
      }

      + .ant-tabs-tab {
        margin-top: ${(p) => p.theme.spacing.xs}
      }
    }
  }

  .tabs-empty {
    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-nav-list .ant-tabs-tab:first-of-type {
      display: none;
    }

    &.tabs-left-in-modal.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:first-child + .ant-tabs-tab {
      margin-top: 0;
    }
  }

  .ant-layout-header {
    display: flex;
    align-items: center;
    box-shadow: 0 2px 2px #ddd;
  }

  .select-field-type-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400;
  }

  

  ${getMedia('max-lg')} {
    .btn-hide-text-mobile [role="img"] + span{
      display: none;
    }
  }

  .ant-picker-full {
    display: block;
  }

  .message-placeholder {
    background-color: ${(p) =>
      p.theme.colors.primary}; border-radius: 4px; color: #ffffff; padding: 2px 4px;

    &.has-error {
      background-color: ${(p) => p.theme.colors.danger};
    }
  }

  .tab-content-has-border {
    > .ant-tabs-nav {
      margin-bottom: 0
    }

    > .ant-tabs-content-holder {
      border: 1px solid #f0f0f0;
      margin-top: -1px; 
    }
  }

  .tox-tinymce-inline {
    z-index: 1000;
  }

  .ant-popover-small-overlay {
    max-width: 250px;
  }

  .ant-card-actions > li {
    margin: 6px 0;
  }

  ${getMedia('max-sm')} {
    .hidden-xs {
      display: none;
    }
  }

  .ant-row {
    margin-bottom: 0;
    
    [class*="ant-col-"] {
      margin-bottom: 16px;
    }
  }
 
  .ant-form-vertical .ant-form-item {
    margin-bottom: 16px;
  }
  
  .ant-upload.ant-upload-drag .ant-upload {
    padding-left: 16px;
    padding-right: 16px;
  }

  .ant-row-clear-col-mb {
    > .ant-col {
      margin-bottom: 0;
    }
  }

  .ant-form-item-has-error .ant-mentions {
    border-color: ${(p) => p.theme.colors.danger};
  }

  .root-el {
    background-color: #ffffff;
  }

  .reset-css {
    * { box-sizing: content-box; }
    mark { background-color: mark; }
    .block-text p { margin: 0 0 1em 0; }
    .block-text p:last-of-type { margin-bottom: 0; }
    strong { font-weight: bold; }
    a { color: -webkit-link; }
    [aria-placeholder] { overflow: hidden; }
  }

  input.clr-color.clr-color {
    border-radius: 8px;
  }

  .clr-preview {
    border-radius: 8px;
  }

  .clr-swatches.clr-swatches button {
    border-radius: 4px;
  }

  .clr-field.clr-field input {
    width: 100%;
    border: 0;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .clr-picker.clr-picker {
    z-index: 1001; 
  }

  .ant-popover-small {
    max-width: 400px;
  }
`;
