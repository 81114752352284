import loadable from '@loadable/component';

export const routes: RouteConfig[] = [
  {
    path: '/login',
    exact: true,
    component: loadable(() => import('../pages/LoginPage')),
  },
  {
    path: '/',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/Dashboard')),
  },
  {
    path: '/edms',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/EdmMgmt')),
  },
  {
    path: '/admin',
    exact: true,
    auth: true,
    component: loadable(() => import('../pages/Admin')),
  },
];
